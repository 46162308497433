import React from 'react';
import { ErrorMessageWrapper, Text } from './styles';

const ErrorMessage = (props) => {
  return (
    <ErrorMessageWrapper>
      <Text>{props.text}</Text>
    </ErrorMessageWrapper>
  );
};

export default ErrorMessage;

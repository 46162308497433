// Dependencies
import React from 'react';
// Styles
import { Label, Check, Box, Input, Text } from './styles';

const Checkbox = (props) => {
  const { checked, children, disabled, name, onChange, primaryColor } = props;

  return (
    <Label disabled={disabled}>
      <Box checked={checked} disabled={disabled} primaryColor={primaryColor}>
        <Input checked={checked} disabled={disabled} name={name} onChange={onChange} type="checkbox" />
        {checked && <Check checked={checked} icon="check" size={11} />}
      </Box>
      {children && <Text>{children}</Text>}
    </Label>
  );
};

Checkbox.defaultProps = {
  checked: true,
  disabled: false,
};

export default Checkbox;

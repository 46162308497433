import styled from 'styled-components';
import { ALABASTER } from 'constants/colors';

export const Row = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 20px;
`;

export const InnerRow = styled(Row)`
  padding: 0;
`;

export const Column = styled.div`
  flex: 1;
  flex-basis: 80%;
  width: auto;

  /* background-color: rgba(255, 0, 0, 0.1); */

  @media only screen and (min-width: 768px) {
    flex: ${(props) => props.span || 1};
    width: ${(props) => ((props.span || 1) / 5) * 1200}px;
    flex-basis: 0%;
  }
`;

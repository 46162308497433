import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Container } from './styles';

import Landing from 'components/pages/Landing';
import Questionaire from 'components/pages/Questionaire';
import OfferAccepted from 'components/pages/OfferAccepted';
import OffersDeleted from 'components/pages/OffersDeleted';

const NotFound = ({ location }) => (
  <div>
    <h1>
      <code>{location.pathname}</code> not found
    </h1>
  </div>
);

class Views extends PureComponent {
  render() {
    return (
      <Container>
        <Switch>
          <Route path="/" component={Landing} exact />
          <Route
            path="/valuations/:valuationId/offers_sent"
            render={(props) => <Questionaire {...props} offersSent />}
            exact
          />
          <Route
            path="/offers/:offerId/offer_accepted"
            render={(props) => <OfferAccepted {...props} offerAccepted />}
            exact
          />
          <Route
            path="/valuations/:valuationId/offers_deleted"
            render={(props) => <OffersDeleted {...props} />}
            exact
          />
          <Route
            path="/questionnaire/thank-you"
            render={(props) => <Questionaire {...props} forceFinished={true} />}
            exact
          />
          <Route
            path="/questionnaire/:customMarketplaceId/thank-you"
            render={(props) => <Questionaire {...props} forceFinished={true} />}
            exact
          />
          <Route
            path="/questionnaire/:customMarketplaceId/:voucher"
            render={(props) => <Questionaire {...props} />}
            exact
          />
          <Route path="/questionnaire/:customMarketplaceId" render={(props) => <Questionaire {...props} />} exact />
          <Route path="/questionnaire" component={Questionaire} exact />
          <Route component={NotFound} status={404} />
        </Switch>
      </Container>
    );
  }
}

export default Views;

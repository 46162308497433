import React, { PureComponent } from 'react';

import { CommendationsContainer } from './styles';
import Commendation from '../Commendation/Commendation';

class Commendations extends PureComponent {
  render() {
    const { commendations } = this.props;

    return (
      <CommendationsContainer>
        {commendations.map((commendation, index) => (
          <Commendation
            key={index}
            title={commendation.title}
            text={commendation.text}
            author={commendation.author}
            companyName={commendation.companyName}
            image={commendation.image}
            alignment={commendation.alignment}
          />
        ))}
      </CommendationsContainer>
    );
  }
}

export default Commendations;

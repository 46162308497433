// Private
export const enhancements = {
  input: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
  checkboxes: {
    isDirty: false,
    isValid: false,
  },
  textarea: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
  select: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
  group_slider: {
    isDirty: false,
    isValid: true,
    isFocused: false,
  },
  remote_autocomplete: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
  free_form_question: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
  input_with_select: {
    isDirty: false,
    isValid: false,
    isFocused: false,
  },
};

// Public
export const generators = (schema) => {
  return {
    ...schema,
    ...enhancements[schema.type],
    isValid: !schema.required,
  };
};

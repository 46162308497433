import React, { PureComponent } from 'react';

import { Container, Label, StyledTextArea } from './styles';

class TextArea extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.props.handleChange(this.props.fieldData, value);
  };

  render() {
    const { required, name, label } = this.props.fieldData;

    return (
      <Container>
        <Label>{label}</Label>
        <StyledTextArea
          fieldData={this.props.fieldData}
          name={name}
          placeholder={this.props.fieldData.placeholder}
          required={required}
          onChange={this.handleChange}
          value={this.props.fieldData.value}
        />
      </Container>
    );
  }
}

export default TextArea;

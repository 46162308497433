import React, { PureComponent } from 'react';
import {
  Container,
  Header,
  Checkmark,
  Text,
  BulletContainer,
  BulletText,
  Bullet,
  ShareLinks,
  ShareLink,
  Image,
  ShareLinkText,
  Footer,
} from './styles';
import { translate } from 'react-i18next';

import LinkedInImage from 'assets/images/share-linkedin.png';
import LinkedInImageRet from 'assets/images/share-linkedin@2x.png';

import TwitterImage from 'assets/images/share-twitter.png';
import TwitterImageRet from 'assets/images/share-twitter@2x.png';

class FinishedPage extends PureComponent {
  render() {
    const {
      t,
      wantsContact,
      autoContact,
      shareUrl,
      baseUrl,
      marketplaceBaseUrl,
      socialsHidden,
      primaryColor,
      secondaryColor,
      customMarketplaceId,
    } = this.props;
    return (
      <Container>
        <Checkmark primaryColor={primaryColor} secondaryColor={secondaryColor}>
          <i className="fal fa-clipboard-list-check" />
        </Checkmark>
        <Header>{t('questionnaire:finished:title')}</Header>
        <Text>{t('questionnaire:finished:text')}</Text>
        <BulletContainer>
          <Bullet className="fas fa-check" primaryColor={primaryColor} secondaryColor={secondaryColor} />
          <BulletText>{t('questionnaire:finished:report_text')}</BulletText>
        </BulletContainer>
        {wantsContact && !autoContact && (
          <BulletContainer>
            <Bullet className="fas fa-check" primaryColor={primaryColor} secondaryColor={secondaryColor} />
            <BulletText>{t('questionnaire:finished:connect_text')}</BulletText>
          </BulletContainer>
        )}
        <div
          style={{
            display: socialsHidden || socialsHidden == undefined ? 'none' : 'contents',
          }}
        >
          <ShareLinks>
            <ShareLink href="https://www.linkedin.com/company/valutico" target="_blank">
              <Image src={LinkedInImage} />
            </ShareLink>
            <ShareLink
              href={
                'https://twitter.com/intent/tweet?text=' +
                encodeURI(t('questionnaire:finished:share_text', { url: shareUrl }))
              }
              target="_blank"
            >
              <Image src={TwitterImage} />
            </ShareLink>
          </ShareLinks>
          <ShareLinkText primaryColor={primaryColor} secondaryColor={secondaryColor}>
            {t('questionnaire:finished:share_link_text')}
          </ShareLinkText>
          <ShareLinkText primaryColor={primaryColor} secondaryColor={secondaryColor}>
            {t('questionnaire:finished:share_link_text2')}
          </ShareLinkText>
        </div>
        <Footer primaryColor={primaryColor}>
          <div style={{ float: 'right' }}>
            <a href={baseUrl}>{t('questionnaire:finished:main_page_link', { url: baseUrl })}</a>
          </div>
          <a href={customMarketplaceId ? `${marketplaceBaseUrl}/questionnaire/${customMarketplaceId}` : '.'}>
            <i className="fas fa-analytics" style={{ color: primaryColor ? primaryColor : 'rgb(138, 186, 69);' }} />
            {t('questionnaire:finished:new_valuation')}
          </a>
        </Footer>
      </Container>
    );
  }
}

export default translate()(FinishedPage);

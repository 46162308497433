import React, { PureComponent } from 'react';

import { StyledButton } from './styles';

class Button extends PureComponent {
  render() {
    const {
      variant,
      type,
      children,
      onClick,
      disabled,
      visibility,
      primaryColor,
      primaryColorLight,
      secondaryColor,
      secondaryColorLight,
    } = this.props;

    return (
      <StyledButton
        variant={variant}
        type={type}
        onClick={onClick}
        disabled={disabled}
        style={{ visibility }}
        primaryColor={primaryColor}
        primaryColorLight={primaryColorLight}
        secondaryColor={secondaryColor}
        secondaryColorLight={secondaryColorLight}
      >
        {children}
      </StyledButton>
    );
  }
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
};

export default Button;

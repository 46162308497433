import React, { PureComponent } from 'react';
import {
  Background,
  Container,
  Header,
  Checkmark,
  Text,
  ContactDetailsTable,
  ContactDetailsTableRow,
  ContactDetailsTableTitle,
  ContactDetailsTableDescription,
} from './styles';
import QuestionnaireHeader from '../../molecules/QuestionnaireHeader/QuestionnaireHeader.js';

import { translate } from 'react-i18next';

class OffersDeleted extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <Background>
        <QuestionnaireHeader />
        <Container>
          <Checkmark>
            <i className="fas fa-check" />
          </Checkmark>
          <Header>{t('offers_deleted:title')}</Header>
          <Text>{t('offers_deleted:text')}</Text>
        </Container>
      </Background>
    );
  }
}

export default translate()(OffersDeleted);

// develop testing api

import i18next from 'i18next';
const axios = require('axios');

export const fetchSuggestions = (url) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  };

  return axios.get(url, config).then((response) => response.data.data);
};

const getUrl = () => {
  if (process.env.NODE_ENV !== 'development') {
    return '___VALUTICO_CONFIG_URL___';
  } else {
    // return 'http://dev.valutico.com:3000/api/marketplaces/default';
    return 'https://app.stg.valutico.dev/api/marketplaces/default';
  }
};

const getMarketplaceUrl = (customMarketplaceId) => {
  const configUrl = getUrl();
  if (customMarketplaceId) {
    return configUrl.replace('/default', `/${customMarketplaceId}`);
  } else {
    return configUrl;
  }
};

export const loadConfig = (customMarketplaceId, voucher) => {
  return axios
    .get(`${getMarketplaceUrl(customMarketplaceId)}?locale=${i18next.language}&voucher=${voucher}`)
    .then((response) => response.data);
  // simulate Network request
  // return new Promise(function (resolve, reject) {
  //   setTimeout(function () {
  //     resolve(devData);
  //   }, 300);
  // });
};

export const loadOffer = (baseUrl, marketplaceId, offerId) => {
  return axios
    .get(`${baseUrl}/api/marketplaces/${marketplaceId}/offers/${offerId}?locale=${i18next.language}`)
    .then((response) => response.data);
};

const devData = {
  id: 'default',
  formVersion: Date(),
  submitPath: 'invalid',
  locale: 'de',
  baseUrl: 'https://staging.valutico.com',
  privacyStatementUrl: 'https://staging.valutico.com/privacy_statement',
  autoContact: true,
  logoHidden: false,
  socialsHidden: false,
  primaryColor: '#1839A0',
  secondaryColor: false,
  intercomEnabled: false,
  vouchersEnabled: true,
  tocLink: 'https://staging.valutico.com',
  questions: [
    {
      id: '25642a58-77e9-4794-9203-18a9aaf2d438',
      type: 'remote_autocomplete',
      question: 'Wo sitzt das Unternehmen?',
      required: true,
      placeholder: 'Bitte ein Land auswählen',
      source: '/api/finance_countries?term=:param&flat=true&locale=de',
      showDefaultOptions: false,
      value: '',
    },
    {
      id: '4caa4ee2-95e1-4657-8ffe-5810b06cbb39',
      type: 'input_with_select',
      question: 'Wie hoch war der Jahresumsatz des Unternehmens zuletzt?',
      description:
        'Hinweis: Der EBIT sollte berichtigt sein für ausserordentliche Einkünfte/Kosten, sowie für die kalkulatorische Miete und den Unternehmerlohn. <a href="https://de.wikipedia.org/wiki/EBIT" target="_blank">Eine ausführliche Definition des EBIT finden Sie auf Wikipedia.de</a>',
      placeholder: 'Jahresumsatz',
      required: true,
      validationRule: '^\\d*$',
      numberFormat: true,
      errorMessage: 'Bitte positive Zahlen eingeben.',
      value: '',
      selectedValue: null,
      options: [
        {
          label: 'Populär',
          options: [
            {
              value: 'EUR',
              label: 'EUR',
            },
            {
              value: 'GBP',
              label: 'GBP',
            },
            {
              value: 'USD',
              label: 'USD',
            },
          ],
        },
        {
          label: 'Weitere',
          options: [
            {
              value: 'ATS',
              label: 'ATS',
            },
            {
              value: 'AUD',
              label: 'AUD',
            },
            {
              value: 'BBD',
              label: 'BBD',
            },
            {
              value: 'BDT',
              label: 'BDT',
            },
            {
              value: 'DEM',
              label: 'DEM',
            },
            {
              value: 'DKK',
              label: 'DKK',
            },
            {
              value: 'DZD',
              label: 'DZD',
            },
          ],
        },
      ],
    },
    {
      id: 'e5b9a792-8e08-4d29-9083-54e5f563f629',
      type: 'remote_autocomplete',
      question: 'In welchem Bereich ist das Unternehmen tätig?',
      required: true,
      placeholder: 'Bitte eine Branche auswählen',
      source: '/api/nace_industries?term=:param&flat=true&locale=de',
      showDefaultOptions: true,
      value: '',
    },
    {
      id: '9d8c9bd4-dcd8-437a-9669-8e46e17f6c24',
      type: 'free_form_question',
      question: 'Umsatzentwicklung der LETZTEN 3 Jahre',
      description: '',
      required: true,
      validationRule: '',
      errorMessage: 'Bitte einen Wert auswählen',
      options: [
        {
          id: '71a71665-f492-46ee-bd9a-6e2fa8959002',
          label: 'Umsatz ist zurückgegangen',
          editable: false,
        },
        {
          id: 'e08943d5-96e7-409a-8575-a1e3c9a3030b',
          label: 'Umsatz ist stagniert',
          editable: false,
        },
        {
          id: '9d89d43b-8789-431a-a919-91b84a5b91cb',
          label: 'Umsatz ist jährlich bis zu 5% gewachsen',
          editable: false,
        },
        {
          id: 'd0d15b1c-f328-4fd2-8b61-26619ec43ffe',
          label: 'Umsatz ist jährlich bis zu 10% gewachsen',
          editable: false,
        },
        {
          id: '3ceea2e1-1d0a-4541-aea9-e712c2eb73fe',
          label: 'Umsatz ist jährlich über 20% gewachsen',
          editable: false,
        },
      ],
    },
    {
      id: 'General',
      type: 'group_slider',
      question: 'Allgemeines',
      description: 'Bitte bewerten Sie das Unternehmen anhand der folgenden "Soft Factors"',
      questions: [
        {
          id: 'f432909e-7002-4f6f-a09b-7cc85bfcfd43',
          'group-name': 'General',
          question: 'Unternehmensgröße',
          questionText: 'Wie groß ist das Unternehmen, relativ zu seinen Wettbewerbern?',
          lowRiskName: 'Groß',
          highRiskName: 'Klein',
          weight: 35,
          min: 1,
          max: 5,
          value: 3,
        },
        {
          id: '6a5275c1-15b7-47b6-9a54-e7772352ad69',
          'group-name': 'General',
          question: 'Positionierung',
          questionText: 'Wie ist die Positionierung der Produkte und/oder Services des Unternehmens?',
          lowRiskName: 'Premium',
          highRiskName: 'Massenware',
          weight: 2,
          min: 1,
          max: 5,
          value: 3,
        },
      ],
    },

    {
      id: 'c60395c0-b63c-466f-91fa-95efcbb25416',
      type: 'remote_autocomplete',
      question: 'Falls anwendbar, welches börsengelistete Unternehmen ist in einer möglichst ähnlichen Branche?',
      placeholder: '',
      source: '/api/peers?term=:param&flat=true',
      value: '',
      required: false,
    },
    {
      id: '137c31ef-b2de-403a-98f5-07499601f006',
      type: 'input',
      question: 'Kurze Beschreibung der Tätigkeit',
      description: '',
      required: true,
      placeholder: '',
      validationRule: '',
      errorMessage: '',
      value: '',
    },
    {
      id: 'b2f489ca-966b-42d2-91c2-83eb09ba3469',
      type: 'free_form_question',
      question: 'Grund für die Bewertung',
      description: '',
      required: true,
      validationRule: '',
      errorMessage: 'Bitte einen Wert auswählen oder einen eigenen Grund eingeben.',
      options: [
        {
          id: '23c53657-5770-40ea-89e3-9804376bb5e9',
          label: 'Potenzieller Verkauf eines Unternehmens',
          editable: false,
        },
        {
          id: '202bffb3-27b0-49d3-b5a7-6d9425761f9b',
          label: 'Potenzieller Kauf eines Unternehmens',
          editable: false,
        },
        {
          id: 'a577042a-0b1d-4660-8982-5012f333e0cb',
          label: 'Steuerliche Gründe',
          editable: false,
        },
        {
          id: 'e00f4537-53e1-46c1-8d44-40a5189d6353',
          label: 'Unternehmensübergabe',
          editable: false,
        },
        {
          id: '6813b1bf-22d1-4486-8954-e8ce72b406cb',
          label: 'Aus reiner Neugier',
          editable: false,
        },
        {
          id: '58904139-4293-4a69-aa5d-1066f4d5867b',
          label: '',
          editable: true,
        },
      ],
    },
  ],
};

import styled from 'styled-components';
import { ALABASTER } from 'constants/colors';

export const Page = styled.div`
  background: ${ALABASTER};
  overflow-x: hidden;
`;

export const Logo = styled.img.attrs({
  src: (props) => props.src,
})`
  align-self: center;
  width: 100%;
  height: auto;

  @media only screen and (min-width: 768px) {
    width: 367px;
  }
`;

export const MobileImageWrapper = styled.div`
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  width: 100%;
`;

export const SampleReportImage = styled.img.attrs({
  src: (props) => props.image,
  srcSet: (props) => `${props.image} 1x, ${props.image.replace('.png', '@2x.png')} 2x`,
})`
  width: 100%;
  height: auto;
`;

import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import history from 'services/history';
import rootSaga from 'store/sagas';
import rootReducer from 'store/reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(sagaMiddleware, routerMiddleware(history));

const store = createStore(rootReducer(history), composeWithDevTools(middlewares));

sagaMiddleware.run(rootSaga);

export default store;

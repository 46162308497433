import React, { PureComponent } from 'react';

import { NavigationContainer, Nav } from './styles';

class Navigation extends PureComponent {
  render() {
    const { footerNav } = this.props;
    return (
      <NavigationContainer>
        <Nav>
          {footerNav.map((navItem, index) => (
            <a key={index} href={navItem.link} target="_blank">
              {navItem.name}
            </a>
          ))}
        </Nav>
      </NavigationContainer>
    );
  }
}

export default Navigation;

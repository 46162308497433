import React, { PureComponent } from 'react';
import {
  Loading,
  Background,
  Container,
  Header,
  Checkmark,
  Text,
  ContactDetailsTable,
  ContactDetailsTableRow,
  ContactDetailsTableTitle,
  ContactDetailsTableDescription,
} from './styles';
import QuestionnaireHeader from '../../molecules/QuestionnaireHeader/QuestionnaireHeader.js';

import { loadConfig, loadOffer } from '../../../utils/api';

import { translate } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

class OfferAccepted extends PureComponent {
  constructor(props) {
    super(props);
    const { offerId } = props.match.params;

    this.state = {
      loading: true,
      offerId: offerId,
      customer: null,
    };
  }

  async componentDidMount() {
    const configResponse = await loadConfig();
    const response = await loadOffer(configResponse.baseUrl, configResponse.id, this.state.offerId);

    this.setState({
      loading: false,
      accepted_ago_text:
        Date.parse(response.accepted_at) < new Date() - 2 * 60 * 1000 ? response.accepted_ago_text : null,
      customer: response.customer,
    });
  }

  render() {
    const { t } = this.props;
    const { customer, accepted_ago_text } = this.state;

    return (
      <Background>
        <QuestionnaireHeader />
        <Container>
          {customer !== null && (
            <>
              <Checkmark>
                <i className="fas fa-check" />
              </Checkmark>
              <Header>{t('offer_accepted:title')}</Header>
              <Text>{t('offer_accepted:text')}</Text>
              <ContactDetailsTable>
                <tbody>
                  <ContactDetailsTableRow>
                    <ContactDetailsTableTitle>{t('offer_accepted:name')}:</ContactDetailsTableTitle>
                    <ContactDetailsTableDescription>{customer.name}</ContactDetailsTableDescription>
                  </ContactDetailsTableRow>
                  <ContactDetailsTableRow>
                    <ContactDetailsTableTitle>{t('offer_accepted:location')}:</ContactDetailsTableTitle>
                    <ContactDetailsTableDescription>{customer.location}</ContactDetailsTableDescription>
                  </ContactDetailsTableRow>
                  {accepted_ago_text && (
                    <ContactDetailsTableRow>
                      <ContactDetailsTableTitle>{t('offer_accepted:accepted_at')}:</ContactDetailsTableTitle>
                      <ContactDetailsTableDescription>{accepted_ago_text}</ContactDetailsTableDescription>
                    </ContactDetailsTableRow>
                  )}
                </tbody>
              </ContactDetailsTable>
              <Text>{t('offer_accepted:additional_infos')}</Text>
            </>
          )}
          <CSSTransition classNames="questionnaire__loading" in={this.state.loading} timeout={300} unmountOnExit>
            <Loading>
              <i className="fas fa-cog fa-spin" />
            </Loading>
          </CSSTransition>
        </Container>
      </Background>
    );
  }
}

export default translate()(OfferAccepted);

export const PRIMARY = '#2950CC'; // '#8ABA45';
export const PRIMARY_LIGHT = '#8DA4EF'; // '#89CC29';
export const SECONDARY = '#21201C'; // '#005571';
export const SECONDARY_LIGHT = '#EDF0F2'; // '#EDF0F2';
export const WHITE = '#FFF'; // '#FFF';
export const BLACK = '#000'; // '#000';
export const GRAY = '#ADAEAF'; // '#ADAEAF';
export const REGAL_BLUE = '#1839A0'; // '#00516F';
export const OXFORD_BLUE = '#353F4F'; // '#353F4F';
export const BIG_STONE = '#1F2D5C'; // '#18333D';
export const SILVER = '#BDBDBD'; // '#BDBDBD';
export const TUNDORA = '#454545'; // '#454545';
export const GRAY_50 = '#979797'; // '#979797';
export const GRAY_05 = '#F7F7F7'; // '#F7F7F7';
export const MERCURY = '#E9E9E9'; // '#E9E9E9';
export const TORCH_RED = '#EB0036'; // '#EB0036';
export const BONDI_BLUE = PRIMARY; // '#0199B2';
export const ALABASTER = '#FAFAFA'; // '#FAFAFA';

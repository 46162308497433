import React, { PureComponent } from 'react';

import Input from '../../atoms/Input';
import ErrorMessage from '../../atoms/ErrorMessage';
import { Container, SelectWrapper, InputWrapper } from './styles';
import Select from 'react-select';

const IndicatorSeparator = ({ innerProps }) => {
  return <span />;
};

class InputWithSelect extends PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.checkInitialInputSelectValue(this.props.fieldData);
  }

  handleInputChange = (value) => {
    this.props.handleChange(this.props.fieldData, value);
  };

  handleSelectClick = (value) => {
    this.props.handleInputSelectClick(value, this.props.fieldData);
  };

  render() {
    return (
      <Container>
        <InputWrapper>
          <Input
            kind="default"
            mode={'isActive'}
            placeholder={this.props.fieldData.placeholder}
            numberformat={this.props.fieldData.numberFormat}
            required
            value={this.props.fieldData.value}
            onChange={this.handleInputChange}
            autoFocus
          />
        </InputWrapper>
        <SelectWrapper>
          <Select
            className="select"
            classNamePrefix="select__grouped"
            components={{ IndicatorSeparator }}
            onChange={this.handleSelectClick}
            options={this.props.fieldData.options}
            value={this.props.fieldData.selectedValue}
            maxMenuHeight={200}
          />
        </SelectWrapper>
        {!this.props.fieldData.isValid && this.props.fieldData.isDirty && (
          <ErrorMessage text={this.props.fieldData.errorMessage} />
        )}
      </Container>
    );
  }
}

export default InputWithSelect;

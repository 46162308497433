import styled from 'styled-components';

import { SECONDARY, MERCURY, SILVER, WHITE, TUNDORA } from 'constants/colors';

export const CommendationContainer = styled.div`
  display: flex;
  margin: 60px 0;
  width: 100%;
  flex-wrap: wrap;

  &:not(:first-child) {
    display: none;
    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
`;

export const Avatar = styled.div`
  border-radius: 50%;
  height: 90px;
  min-width: 90px;
  background: ${SILVER};
  overflow: hidden;
  background-image: url(${(props) => props.image});
  background-size: cover;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const AvatarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  flex-direction: ${(props) => (props.alignment === 'left' ? 'row' : 'row-reverse')};
  padding: 0;
  padding-top: 35px;

  @media only screen and (min-width: 768px) {
    padding: 35px 0 35px 0;
    padding-left: ${(props) => (props.alignment === 'left' ? '60px' : '36px')};
    padding-right: ${(props) => (props.alignment === 'left' ? '36px' : '60px')};
  }
`;

export const AvatarCaption = styled.div`
  text-align: ${(props) => (props.alignment === 'left' ? 'left' : 'right')};
  ${(props) => (props.alignment === 'left' ? 'margin-left: 20px' : 'margin-right: 20px')};
  display: flex;
  flex-direction: column;
`;

AvatarCaption;

export const CommendationContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  border-radius: 10px;
  background: ${WHITE};
  position: relative;
  z-index: 3;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.h3`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  font-size: 24px;
  font-weight: bold;
  line-height: 62px;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 1.72;
  }
`;

export const Seperator = styled.div`
  content: '';
  height: 2px;
  background: ${TUNDORA};
  border-radius: 5px;
  opacity: 0.2;
  margin: 0 64px 16px 64px;

  @media only screen and (min-width: 768px) {
    margin: 10px 103px 30px 103px;
  }
`;

export const QuoatationMark = styled.span`
  align-self: end;
  color: ${MERCURY};
  font-size: 118px;
  font-weight: 700;
  height: 102px;
  margin-right: 13px;
  line-height: 0.8;
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

export const Text = styled.p`
  color: ${TUNDORA};
  font-size: 18px;
  line-height: 1.65;
  margin: 0 0 5px 0;
`;

export const Author = styled.p`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.08;
  margin: 0 0 10px 0;
`;

export const CompanyName = styled.p`
  color: ${SILVER};
  font-size: 18px;
  line-height: 1.28;
  margin: 0 0 10px 0;
`;

export const ShadowBox = styled.div`
  height: auto;
  box-shadow: 0 7px 14px 0 ${MERCURY};
  border-radius: 10px;
  position: relative;
  z-index: 1;
`;

export const ShadowSquare = styled.span`
  width: 60px;
  height: 60px;
  box-shadow: 0 7px 29px 0 ${MERCURY};
  background: ${WHITE};
  position: absolute;
  transform: rotate(45deg);
  bottom: 50px;
  ${(props) => (props.alignment == 'left' ? 'right: -8px' : 'left: -8px')};
  z-index: 2;
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

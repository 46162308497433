import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Button from '../../atoms/Button/Button';

import { InnerRow, Column } from '../../../constants/grid';

import { StepContainer, ImageColumn, StepImage, Image, StepNumber, Title, Text } from './styles';

class Step extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      scrolled: false,
      positionTop: null,
      revealSection: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleStepScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleStepScroll);
  }

  handleStepScroll = () => {
    const top = ReactDOM.findDOMNode(this).getBoundingClientRect().top;
    if (top < document.documentElement.clientHeight - 100) {
      this.setState({ revealSection: true });
    }
  };

  redirect = () => {
    this.props.openQuestionnaire();
  };

  render() {
    const { type, stepNumber, title, stepContent, callToAction, image } = this.props;

    const textColumn = (
      <Column span="2" key="text">
        <StepNumber>{stepNumber}</StepNumber>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: stepContent }} />
        <Button onClick={this.redirect}>
          <b>{callToAction}</b>
        </Button>
      </Column>
    );

    const imageColumn = (
      <ImageColumn span="2" key="image">
        <StepImage type={type} revealSection={this.state.revealSection}>
          <Image image={image} />
        </StepImage>
      </ImageColumn>
    );

    let elements = [textColumn, <Column key="spacer" />, imageColumn];
    if (type == 'right') elements.reverse();

    return (
      <StepContainer>
        <InnerRow>{elements}</InnerRow>
      </StepContainer>
    );
  }
}

export default Step;

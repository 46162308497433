import React, { PureComponent } from 'react';

import {} from './styles';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class RangedInput extends PureComponent {
  render() {
    return (
      <div>
        {this.props.fieldData.questions.map((question, index) => (
          <div key={index}>
            <h3 className="tooltip tooltip--multiline">
              {question.question}
              <div className="tooltip__content">
                <div className="tooltip__content__text">{question.questionText}</div>
              </div>
            </h3>
            <div>
              <div className="input--range">
                <p className="input--range__label input--range__label--left">{question.lowRiskName}</p>
                <input
                  type="range"
                  className="input--range__input"
                  defaultValue={question.value}
                  step={1}
                  min={1}
                  max={5}
                  onChange={(event) =>
                    this.props.handleRangeInputChange(index, this.props.fieldData.id, parseInt(event.target.value))
                  }
                />
                <p className="input--range__label">{question.highRiskName}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RangedInput;

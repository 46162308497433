import { createGlobalStyle } from 'styled-components';
import 'sanitize.css';
import { hexToRgb } from '../../../utils/helpers';
import SourceSansPro_Light from 'assets/fonts/SourceSansPro-Light.woff';
import SourceSansPro_Regular from 'assets/fonts/SourceSansPro-Regular.woff';
import SourceSansPro_Bold from 'assets/fonts/SourceSansPro-Bold.woff';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: 400;
  }

  html,
  body,
  #app {
    height: 100%;
  }

  a {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    text-decoration: none;
  }

  b, strong {
    font-weight: bold;
  }

  .select .select__autocomplete__single-value {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .select .select__autocomplete__control--is-focused {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .select .select__autocomplete__control--menu-is-open {
    border-radius: 4px 4px 0 0;
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    border-bottom: none;
    padding-bottom: 2px;
  }
  
  .select .select__autocomplete__control:hover {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .select .select__autocomplete__dropdown-indicator {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .select .select__autocomplete__single-value {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .select .select__autocomplete__menu {
    margin-top: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    border-top: none;
  }

  .select .select__grouped__control:hover {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  .select .select__grouped__control--is-focused {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }
  
  .select .select__grouped__dropdown-indicator {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }
  
  .select .select__grouped__value-container {
    outline: none;
  }
  
  .select .select__grouped__single-value {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }
  
  .select .select__grouped__menu {
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .select .select__grouped__option--is-selected::before {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .input--text {
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 2px var(--grey-10);
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  
    transition: all var(--duration-default) var(--transition-timing-function);
    outline: none;
  }

  .input--text:hover {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .input--text:focus {
    border: solid 2px ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .tooltip::after {
    margin-left: 5px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    content: '\f05a';
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
  }

  .tooltip {
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    font-weight: 600;
    font-size: 18px;
    line-height: 1.28;
    display: inline-block;
    cursor: help;
  }

  .input--range__input::-webkit-slider-thumb {
    box-sizing: border-box;
    box-shadow: 0 3px 1px rgba(0, 85, 113, 0.12);
    border: 11px solid ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')};
    height: 34px;
    width: 34px;
    border-radius: 17px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
    transition: all var(--duration-default) var(--transition-timing-function);
  }
  
  .input--range__input:focus::-webkit-slider-thumb {
    box-shadow: none;
    border-color: ${(props) => (props.primaryColorLight ? props.primaryColorLight : 'var(--primary-light)')};
  }

  .input--range__input::-webkit-slider-runnable-track {
    width: 100%;
    height: 14px;
    cursor: pointer;
    ${(props) => {
      if (props.secondaryColor && props.secondaryColor !== "#FFFFFF") {
        const secondaryShadow = hexToRgb(props.secondaryColor);
        return `background-color: rgba(${secondaryShadow['r']}, ${secondaryShadow['g']}, ${secondaryShadow['b']}, 0.1) !important`;
      }
      return 'background: var(--ice-blue);';
    }}
    border-radius: 7px;
  }

  .input--range__input:focus::-webkit-slider-runnable-track {
    ${(props) => {
      if (props.secondaryColor && props.secondaryColor !== "#FFFFFF") {
        const secondaryShadow = hexToRgb(props.secondaryColor);
        return `background-color: rgba(${secondaryShadow['r']}, ${secondaryShadow['g']}, ${secondaryShadow['b']}, 0.2) !important`;
      }
      return 'background: #E1E9FF';
    }}
  }

  .input--range__input::-moz-range-thumb {
    box-sizing: border-box;
    box-shadow: 0 3px 1px rgba(0, 85, 113, 0.12);
    border: 11px solid ${(props) => (props.primaryColor ? props.primaryColor : 'var')};
    height: 34px;
    width: 34px;
    border-radius: 17px;
    background: #ffffff;
    cursor: pointer;
    transition: all var(--duration-default) var(--transition-timing-function);
  }
  
  .input--range__input:focus::-moz-range-thumb {
    box-shadow: none;
    border-color: ${(props) => (props.primaryColorLight ? props.primaryColorLight : 'var(--primary-light)')};
  }

  .input--range__input::-moz-range-track {
    width: 100%;
    height: 14px;
    cursor: pointer;
    box-shadow: none;
    ${(props) => {
      if (props.secondaryColorLight) {
        return `background: ${props.secondaryColorLight};
        opacity: 0.1;`;
      }
      return 'background: var(--ice-blue);';
    }}
    border-radius: 7px;
  }
  .input--range__input:focus::-moz-range-track {
    ${(props) => {
      if (props.secondaryColorLight) {
        return `background: ${props.secondaryColorLight};
        opacity: 0.1;`;
      }
      return 'background: #daecf2;';
    }}
  }

  .input--range__input::-ms-thumb {
    box-sizing: border-box;
    box-shadow: 0 3px 1px rgba(0, 85, 113, 0.12);
    border: 11px solid ${(props) => (props.primaryColor ? props.primaryColor : 'var')};
    height: 34px;
    width: 34px;
    border-radius: 17px;
    background: #ffffff;
    margin-top: 0;
  }
  
  .react-international-phone-input {
    box-sizing: border-box;
    width: 100%;
    min-height: 50px !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: solid 2px var(--grey-10) !important;
    background-color: #ffffff !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')} !important;
  
    transition: all var(--duration-default) var(--transition-timing-function);
    outline: none;
  }

  .react-international-phone-input:hover {
    border-color: ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')} !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .react-international-phone-input:focus {
    border: solid 2px ${(props) => (props.primaryColor ? props.primaryColor : 'var(--primary)')} !important;
  }
`;

export default GlobalStyles;

import React, { PureComponent } from 'react';

import { PartnerLogosContainer, Logo } from './styles';
import logoBmd from 'assets/images/logo-bmd.png';
import logoDatev from 'assets/images/logo-datev.png';
import logoDvfa from 'assets/images/logo-dvfa.png';
import logoCorporateFinance from 'assets/images/logo-corporate-finance.png';
import logoHandelsblatt from 'assets/images/logo-handelsblatt.png';
import logoSandp from 'assets/images/logo-sandp.png';
import logoEacva from 'assets/images/logo-eacva.png';
import logoSfaf from 'assets/images/logo-sfaf.jpg';

class Hero extends PureComponent {
  render() {
    return (
      <PartnerLogosContainer hasBorder={this.props.hasBorder}>
        <Logo image={logoDatev} />
        <Logo image={logoBmd} />
        <Logo image={logoSandp} />
        <Logo image={logoHandelsblatt} />
        <Logo image={logoCorporateFinance} />
        <Logo image={logoDvfa} />
        <Logo image={logoEacva} />
        <Logo image={logoSfaf} />
      </PartnerLogosContainer>
    );
  }
}

export default Hero;

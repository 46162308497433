import React, { PureComponent } from 'react';
import { translate, Trans } from 'react-i18next';

import Button from 'components/atoms/Button';

import { Container, Bar, ProgressBarWrapper, ButtonsWrapper, TimeLeft } from './styles';

class Pagination extends PureComponent {
  handleClick = (disabled, direction) => {
    if (disabled) {
      return false;
    }
    this.props.changePage(direction);
  };
  render() {
    const { t } = this.props;

    const {
      previousQuestionDisabled,
      nextQuestionDisabled,
      currentPage,
      maxSteps,
      isSubmitPage,
      isInIFrame,
      primaryColor,
      primaryColorLight,
      secondaryColor,
      secondaryColorLight,
    } = this.props;
    const minutesLeft = Math.round((1 - (currentPage - 1) / maxSteps) * 15);
    return (
      <Container>
        {!isSubmitPage && (
          <>
            <ButtonsWrapper secondaryColor={secondaryColor} secondaryColorLight={secondaryColorLight}>
              <Button
                variant="transparentBack"
                disabled={previousQuestionDisabled}
                visibility={isInIFrame && currentPage === 1 ? 'hidden' : 'visible'}
                onClick={this.handleClick.bind(this, previousQuestionDisabled, 'DEC')}
                primaryColor={primaryColor}
                primaryColorLight={primaryColorLight}
                secondaryColor={secondaryColor}
                secondaryColorLight={secondaryColorLight}
              >
                {t('questionnaire:pagination:back')}
              </Button>
              <Button
                disabled={nextQuestionDisabled}
                onClick={this.handleClick.bind(this, nextQuestionDisabled, 'INC')}
                primaryColor={primaryColor}
                primaryColorLight={primaryColorLight}
                secondaryColor={secondaryColor}
                secondaryColorLight={secondaryColorLight}
              >
                {t('questionnaire:pagination:next')}
              </Button>
              <TimeLeft primaryColor={primaryColor}>
                <Trans i18nKey="questionnaire:pagination:minutes_left" count={minutesLeft}>
                  <span>ca.</span>
                  {{ minutesLeft }} Minuten
                </Trans>
                <i className="fal fa-clock" />
              </TimeLeft>
            </ButtonsWrapper>
          </>
        )}
        <ProgressBarWrapper>
          <Bar progress={(currentPage / maxSteps) * 100} primaryColor={primaryColor} secondaryColor={secondaryColor} />
        </ProgressBarWrapper>
      </Container>
    );
  }
}

export default translate()(Pagination);

import styled from 'styled-components';
import { TORCH_RED, WHITE } from 'constants/colors';

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  border-radius: 4px;
  background-color: ${TORCH_RED};
  text-align: center;
  padding: 13px;
`;

export const Text = styled.p`
  display: flex;
  color: ${WHITE};
  font-size: 16px;
  margin: 0;
`;

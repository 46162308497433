import 'sanitize.css/sanitize.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import OtaClient from '@crowdin/ota-client';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/react';

import en from 'config/locales/en.json';
import de from 'config/locales/de.json';
import fr from 'config/locales/fr.json';
import es from 'config/locales/es.json';
import it from 'config/locales/it.json';
import nl from 'config/locales/nl.json';
import pt from 'config/locales/pt.json';

import store from 'store';
import history from 'services/history';
import App from './components/organisms/App';
import './assets/styles/main.css';

import { polyfillReplaceAll } from './utils/polyfills';

const packageJson = require('../package.json');
const sentryRelease = process.env.SENTRY_ENV === 'production' || process.env.SENTRY_ENV === 'demo' ? "v" + packageJson.version : 'develop';

Sentry.init({
  dsn: process.env.NODE_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: sentryRelease,
});

const crowdinHash = 'a8df6c2222f7cac0995d8f9ekdp';
class CrowdinOtaI18next {
  constructor() {
    this.type = 'backend';
    this.otaClient = new OtaClient(crowdinHash);
    this.languageMappings = { es: 'es-ES' };
  }

  read(language, namespace, callback) {
    const resolvedLanguage = this.languageMappings[language] || language;

    this.otaClient
      .getStringsByLocale(resolvedLanguage)
      .then((value) => callback(null, value[namespace]))
      .catch((e) => callback(e, null));
  }
}

i18next
  .use(new CrowdinOtaI18next())
  .use(LanguageDetector)
  .init({
    resources: { en, de, fr, es, it, nl, pt },
    partialBundledLanguages: true,
    whitelist: ['en', 'de', 'fr', 'es', 'it', 'nl', 'pt'],
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common', 'input', 'landing', 'offer_accepted', 'offers_deleted', 'questionnaire'],
    defaultNS: 'common',
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    detection: {
      lookupQuerystring: 'locale',
    },
  });

const setVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
setVH();
window.addEventListener('resize', setVH);

polyfillReplaceAll();

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

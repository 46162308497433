const ICONS = {
  'arrow-down': ['M0.001 8h31.999l-15.999 15.999-15.999-15.999z'],
  'arrow-left': ['M24 0v32l-16-16 16-16z'],
  'arrow-right': ['M8 0.001v31.999l15.999-15.999-15.999-15.999z'],
  'arrow-up': ['M0.001 24h31.999l-15.999-15.999-15.999 15.999z'],
  'angle-down': [
    'm31 16.4q0 0.3-0.2 0.5l-10.4 10.4q-0.3 0.3-0.5 0.3t-0.6-0.3l-10.4-10.4q-0.2-0.2-0.2-0.5t0.2-0.5l1.2-1.1q0.2-0.2 0.5-0.2t0.5 0.2l8.8 8.8 8.7-8.8q0.3-0.2 0.5-0.2t0.6 0.2l1.1 1.1q0.2 0.2 0.2 0.5z',
  ],
  X: [
    'M11.808 15.941l-10.462-10.382c-1.238-1.229-1.235-3.232-0.016-4.451l-0.292 0.292c1.229-1.229 3.219-1.233 4.459 0.008l10.422 10.422 10.576-10.576c1.232-1.232 3.207-1.254 4.459-0.002l-0.247-0.247c1.231 1.231 1.232 3.225-0.008 4.465l-10.57 10.57 10.568 10.568c1.231 1.231 1.222 3.236 0.003 4.455l0.292-0.292c-1.229 1.229-3.23 1.222-4.455-0.003l-10.568-10.568-10.485 10.485c-1.221 1.221-3.188 1.235-4.44-0.017l0.247 0.247c-1.231-1.231-1.228-3.229-0.008-4.448l10.525-10.525z',
  ],
  check: [
    'M32 8.462q0 0.832-0.555 1.48l-17.757 17.665q-0.555 0.647-1.387 0.647t-1.48-0.647l-10.266-10.266q-0.555-0.555-0.555-1.387t0.555-1.387l2.867-2.775q0.555-0.647 1.387-0.647t1.387 0.647l6.104 6.012 13.503-13.503q0.555-0.555 1.387-0.555t1.387 0.555l2.867 2.775q0.555 0.555 0.555 1.387z',
  ],
  trash: [
    'M5.6 27.2h20.8c1.325 0 2.4 1.075 2.4 2.4s-1.075 2.4-2.4 2.4h-20.8c-1.325 0-2.4-1.075-2.4-2.4s1.075-2.4 2.4-2.4z',
    'M2.4 8h27.2c1.325 0 2.4 1.075 2.4 2.4s-1.075 2.4-2.4 2.4h-27.2c-1.325 0-2.4-1.075-2.4-2.4s1.075-2.4 2.4-2.4z',
    'M7.2 0h17.6c1.325 0 2.4 1.075 2.4 2.4s-1.075 2.4-2.4 2.4h-17.6c-1.325 0-2.4-1.075-2.4-2.4s1.075-2.4 2.4-2.4z',
    'M5.6 31.36c-1.325 0-2.4-1.050-2.4-2.412v-16.936c0-1.332 1.065-2.412 2.4-2.412v0c1.325 0 2.4 1.050 2.4 2.412v16.936c0 1.332-1.065 2.412-2.4 2.412v0z',
    'M26.4 31.36c-1.325 0-2.4-1.050-2.4-2.412v-16.936c0-1.332 1.065-2.412 2.4-2.412v0c1.325 0 2.4 1.050 2.4 2.412v16.936c0 1.332-1.065 2.412-2.4 2.412v0z',
    'M16 31.36c-1.325 0-2.4-1.050-2.4-2.412v-16.936c0-1.332 1.065-2.412 2.4-2.412v0c1.325 0 2.4 1.050 2.4 2.412v16.936c0 1.332-1.065 2.412-2.4 2.412v0z',
  ],
  upload: [
    'M21.282 15.641c0.223 0.223 0.531 0.361 0.871 0.361 0.681 0 1.232-0.552 1.232-1.232 0-0.34-0.138-0.648-0.361-0.871v0l-6.154-6.154c-0.223-0.223-0.531-0.362-0.871-0.362s-0.649 0.138-0.871 0.362v0l-6.154 6.154c-0.223 0.223-0.361 0.531-0.361 0.871 0 0.681 0.552 1.232 1.232 1.232 0.34 0 0.648-0.138 0.871-0.361v0l5.282-5.285 5.282 5.285z',
    'M16 32c-8.837 0-16-7.163-16-16s7.163-16 16-16v0c8.837 0 16 7.163 16 16s-7.163 16-16 16v0zM16 29.538c7.477 0 13.538-6.061 13.538-13.539s-6.061-13.539-13.539-13.539v0c-7.477 0-13.539 6.061-13.539 13.539s6.061 13.539 13.539 13.539v0z',
    'M17.231 23.655v-14.769c0-0.68-0.551-1.231-1.231-1.231s-1.231 0.551-1.231 1.231v0 14.769c0 0.68 0.551 1.231 1.231 1.231s1.231-0.551 1.231-1.231v0z',
  ],
  loupe: [
    'M22.169 13.538q0-3.622-2.501-6.122t-6.122-2.501-6.036 2.501-2.587 6.122 2.501 6.036 6.122 2.587 6.122-2.587 2.501-6.036zM31.999 29.491q0 1.035-0.69 1.811t-1.725 0.69q-1.035 0-1.725-0.69l-6.64-6.64q-3.449 2.414-7.674 2.414-2.759 0-5.26-1.121t-4.311-2.846-2.932-4.311-1.035-5.26 1.035-5.26 2.932-4.398 4.311-2.846 5.26-1.035 5.26 1.035 4.311 2.846 2.932 4.398 1.035 5.26q0 4.225-2.328 7.674l6.553 6.553q0.69 0.776 0.69 1.725z',
  ],
};

export default ICONS;

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';

import { Container, Title, SubTitle, QuestionNumber, ElementWrapper } from './styles';

import Checkboxes from 'components/atoms/Checkboxes';
import TextArea from 'components/atoms/TextArea';
import RangedInput from 'components/atoms/RangedInput';
import QuestionaireInput from '../../pages/Questionaire/custom/QuestionaireInput';
import AutocompleteSelect from 'components/molecules/AutocompleteSelect';
import FreeForm from 'components/molecules/FreeFormQuestion';
import InputWithSelect from 'components/molecules/InputWithSelect';

const handlers = {
  checkboxes: Checkboxes,
  textarea: TextArea,
  input: QuestionaireInput,
  group_slider: RangedInput,
  remote_autocomplete: AutocompleteSelect,
  free_form_question: FreeForm,
  input_with_select: InputWithSelect,
};

class Question extends PureComponent {
  render() {
    const { currentPage, maxSteps, primaryColor, secondaryColor, t } = this.props;
    const { question, description } = this.props.fieldData;
    const Element = handlers[this.props.fieldData.type];

    const customProps = {
      searchable: this.props.fieldData.type == 'remote_autocomplete' ? 'searchable' : null,
    };

    return (
      <Container>
        <QuestionNumber primaryColor={primaryColor} secondaryColor={secondaryColor}>
          {t('questionnaire:questionCountText', {
            currentPage: currentPage,
            pageCount: maxSteps - 1,
          })}
        </QuestionNumber>
        <Title primaryColor={primaryColor} secondaryColor={secondaryColor}>
          {question}
        </Title>
        {description && <SubTitle dangerouslySetInnerHTML={{ __html: description }} />}
        <ElementWrapper>
          <Element
            fieldData={this.props.fieldData}
            {...this.props}
            {...customProps}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        </ElementWrapper>
      </Container>
    );
  }
}

export default translate()(Question);

import styled from 'styled-components';

export const PartnerLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  border-top: ${(props) => (props.hasBorder ? '2px solid rgba(69, 69, 69, 0.2)' : 'none')};
`;

export const Logo = styled.img.attrs({
  src: (props) => props.image,
})`
  align-self: center;
  max-height: 60px;
  width: 40%;
  object-fit: contain;
  object-position: left;
  margin: 10px;

  @media only screen and (min-width: 768px) {
    max-width: 130px;
    width: auto;
    margin: 0;
  }
`;

import styled from 'styled-components';

import { BIG_STONE, WHITE } from 'constants/colors';

export const FooterContainer = styled.div`
  background: ${BIG_STONE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 61px 0;
`;

export const Copyright = styled.div`
  color: ${WHITE};
  font-size: 15px;
`;

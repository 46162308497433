import React from 'react';
import ICONS from '../../../constants/icons';

const Icons = (props) => {
  const { onClick, width, height, size, icon, className, fill } = props;

  return (
    <svg className={className} height={height || size} onClick={onClick} viewBox="0 0 32 32" width={width || size}>
      {ICONS[icon].map((d, i) => (
        <path key={d} d={d} fill={fill} />
      ))}
    </svg>
  );
};

Icons.defaultProps = {
  className: '',
  fill: 'grey',
  height: 0,
  size: 10,
  width: 0,
};

export default Icons;

import styled from 'styled-components';

import { WHITE } from 'constants/colors';

export const CallToActionContainer = styled.div`
  background-image: linear-gradient(30deg, #8da4ef, #2950cc 37%, #1f2d5c);
`;

export const CallToActionContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 76px 0;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
`;

export const CallToActionContent = styled.div`
  width: 526px;
  text-align: center;
`;

export const Title = styled.h2`
  color: ${WHITE};
  font-size: 35px;
  font-weight: 700;
  line-height: 1.08;
  margin: 0 0 20px 0;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }
`;

export const Subtitle = styled.p`
  color: ${WHITE};
  font-size: 20px;
  font-weight: 300;
  line-height: 1.38;
  opacity: 0.8;
  margin: 0 0 20px 0;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

import React, { PureComponent } from 'react';
import { Row, Column } from '../../../constants/grid';
import Button from 'components/atoms/Button';

import { SectionContainer, TitleWrapper, SectionContent, Title } from './styles';

class Section extends PureComponent {
  render() {
    const { sectionTitle, titleAlignment, alignItems, children } = this.props;

    return (
      <SectionContainer>
        <Row>
          <Column span="5">
            <TitleWrapper titleAlignment={titleAlignment}>
              <Title titleAlignment={titleAlignment}>{sectionTitle}</Title>
            </TitleWrapper>
            <SectionContent alignItems={alignItems}>{children}</SectionContent>
          </Column>
        </Row>
      </SectionContainer>
    );
  }
}

export default Section;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const StyledCheckbox = styled.div`
  display: block;
`;

export const StyledCheckboxInput = styled.input`
  display: inline-block;
  height: 16px;
  width: 16px;
  /* position: absolute; */
  z-index: 2;
  /* opacity: 0; */
`;

export const StyledCheckboxLabel = styled.label`
  display: inline-block;
`;

// export const StyledLabel = styled.label`
//   display: inline-block;
//   cursor: pointer;
//   margin-right: 5px;
//   /* position: relative; */
//   height: 16px;
//   width: 16px;
// `;

// export const FakeCheckbox = styled.label`
//   display: block;
//   height: 16px;
//   width: 16px;
//   border-radius: 3px;
//   background-color: ${WHITE};
//   border: 1px solid ${SECONDARY};
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1;

//   ${props => props.checked && `
//     &:before {
//       content: '';
//       border-radius: 1px;
//       display: block;
//       position: absolute;
//       top: 3px;
//       left: 3px;
//       background-color: ${SECONDARY};
//       height: 8px;
//       width: 8px;
//     }
//   `}
// `;

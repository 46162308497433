import React, { PureComponent } from 'react';

import { FooterContainer, Copyright } from './styles';

import Navigation from '../Navigation/Navigation';

class Footer extends PureComponent {
  render() {
    const { footerNav } = this.props;
    return (
      <FooterContainer>
        <Navigation footerNav={footerNav} />
        <Copyright>Copyright&copy; 2021 Valutico all rights reserved</Copyright>
      </FooterContainer>
    );
  }
}

export default Footer;

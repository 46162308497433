import styled from 'styled-components';

import Icons from '../Icon';
import { PRIMARY } from 'constants/colors';

export const Label = styled.label`
  display: flex;
  margin-bottom: 10px;
`;

export const Check = styled(Icons)`
  path {
    fill: ${(props) => (props.checked ? '#FFFFFF' : 'transparent')};
  }
`;

export const Box = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${(props) => (props.checked ? (props.primaryColor ? props.primaryColor : PRIMARY) : '#FFFFFF')};
  border-radius: 2px;
  border: 1px solid ${(props) => (props.checked ? (props.primaryColor ? props.primaryColor : PRIMARY) : '#C5D3D6')};
  cursor: pointer;
  display: flex;
  height: 15px;
  justify-content: center;
  min-height: 15px;
  min-width: 15px;
  width: 15px;
`;

export const Text = styled.span`
  margin-left: 8px;
`;

export const Input = styled.input`
  display: none;
`;

import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Column } from '../../../constants/grid';

import {
  QuestionsContainer,
  MobileQuestionsContainer,
  ColumnLeft,
  ColumnRight,
  TabsNav,
  Tab,
  TabContent,
  Title,
  Text,
} from './styles';

class QuestionExample extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      activeTab: 0,
    };
  }

  setActiveTab = (index) => {
    this.setState({ activeTab: index });
  };
  render() {
    const { questions } = this.props;

    return (
      <div>
        <QuestionsContainer>
          <Column span="2">
            <TabsNav>
              {questions.map((question, index) => (
                <Tab key={index} onClick={() => this.setActiveTab(index)} isActive={index === this.state.activeTab}>
                  <Title>{question.title}</Title>
                </Tab>
              ))}
            </TabsNav>
          </Column>
          <Column span="3">
            {questions.map((question, index) => {
              return (
                <CSSTransition
                  in={index === this.state.activeTab}
                  timeout={300}
                  classNames="faq__answer"
                  unmountOnExit
                  key={index}
                >
                  <TabContent>
                    <Title>{question.title}</Title>
                    <Text dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </TabContent>
                </CSSTransition>
              );
            })}
          </Column>
        </QuestionsContainer>
        <MobileQuestionsContainer>
          {questions.map((question, index) => (
            <Tab key={index} isActive={true}>
              <Title>{question.title}</Title>
              <Text dangerouslySetInnerHTML={{ __html: question.answer }} />
            </Tab>
          ))}
        </MobileQuestionsContainer>
      </div>
    );
  }
}

export default QuestionExample;

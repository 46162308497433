import React, { PureComponent } from 'react';
import { StepsContainer } from './styles';
import Step from '../Step/Step';
import i18next from 'i18next';

// images
require('assets/images/step-1-en.png');
require('assets/images/step-1-de.png');
require('assets/images/step-2-en.png');
require('assets/images/step-2-de.png');
require('assets/images/step-3.png');
require('assets/images/step-1-en-mobile.png');
require('assets/images/step-1-de-mobile.png');
require('assets/images/step-2-en-mobile.png');
require('assets/images/step-2-de-mobile.png');
require('assets/images/step-3-mobile.png');
require('assets/images/step-1-en@2x.png');
require('assets/images/step-1-de@2x.png');
require('assets/images/step-2-en@2x.png');
require('assets/images/step-2-de@2x.png');
require('assets/images/step-3@2x.png');
require('assets/images/step-1-en-mobile@2x.png');
require('assets/images/step-1-de-mobile@2x.png');
require('assets/images/step-2-en-mobile@2x.png');
require('assets/images/step-2-de-mobile@2x.png');
require('assets/images/step-3-mobile@2x.png');

class Steps extends PureComponent {
  render() {
    const { steps, openQuestionnaire } = this.props;

    return (
      <StepsContainer>
        {steps.map((step, index) => (
          <Step
            key={index}
            type={step.type}
            stepNumber={step.stepNumber}
            title={step.title}
            stepContent={step.stepContent}
            callToAction={step.callToAction}
            image={window.innerWidth < 768 ? step.mobileImage : step.image}
            openQuestionnaire={openQuestionnaire}
          />
        ))}
      </StepsContainer>
    );
  }
}

export default Steps;

import React from 'react';
import { Check, Box, Input } from './styles';

const Radio = (props) => {
  return (
    <Box checked={props.value}>
      <Input
        checked={props.value}
        name={props.name}
        onChange={props.onChange}
        type="radio"
        id={props.id}
        value={props.value}
      />
      {props.value && (
        <Check checked={props.value} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} />
      )}
    </Box>
  );
};

export default Radio;

import React, { PureComponent } from 'react';
import {NumericFormat} from 'react-number-format';
import i18next from 'i18next';

class Input extends PureComponent {
  constructor() {
    super();
  }

  handleInput = (event) => {
    this.props.onChange(event.target.value);
  };

  handleNumberInput = (values) => {
    this.props.onChange(values.value);
  };

  render() {
    let numberOfRows = this.props.lines || 1;

    let commonProps = {
      className: 'input--text',
      onFocus: this.props.onFocus,
      name: this.props.name,
      placeholder: this.props.placeholder,
      required: this.props.required,
      value: this.props.value,
      rows: numberOfRows,
    };

    if (this.props.numberformat) {
      let locale = i18next.language;
      let localeString = (1000.1).toLocaleString(locale);
      let thousandsSeparator = localeString[1];
      if (thousandsSeparator == '0') thousandsSeparator = '.';
      let comma = localeString.slice(-2, -1);

      return (
        <NumericFormat
          {...commonProps}
          onValueChange={this.handleNumberInput}
          thousandSeparator={thousandsSeparator}
          decimalSeparator={comma}
          inputMode="numeric"
          pattern="-?[0-9]*"
        />
      );
    } else if (numberOfRows > 1) {
      return <textarea {...commonProps} onChange={this.handleInput} />;
    } else {
      return <input {...commonProps} onChange={this.handleInput} />;
    }
  }
}

export default Input;

import React, { PureComponent } from 'react';

import { Column } from '../../../constants/grid';

import {
  CommendationContainer,
  Avatar,
  AvatarWrapper,
  AvatarContent,
  AvatarCaption,
  ContentWrapper,
  Title,
  TitleWrapper,
  Seperator,
  CommendationContent,
  QuoatationMark,
  Text,
  Author,
  CompanyName,
  ShadowBox,
  ShadowSquare,
} from './styles';
import Friedrich from 'assets/images/testimonial-friedrich.png';
import Johannes from 'assets/images/testimonial-johannes';
import Michael from 'assets/images/testimonial-michael';

class Commendation extends PureComponent {
  render() {
    const { image, title, text, author, companyName, alignment } = this.props;

    const bubbleColumn = (
      <Column span="3" key="bubble">
        <ShadowBox>
          <ShadowSquare alignment={alignment} />
          <CommendationContent>
            <TitleWrapper>
              <Title>{title}</Title>
            </TitleWrapper>
            <Seperator />
            <ContentWrapper>
              <QuoatationMark>”</QuoatationMark>
              <Text dangerouslySetInnerHTML={{ __html: text }} />
            </ContentWrapper>
          </CommendationContent>
        </ShadowBox>
      </Column>
    );

    const avatarColumn = (
      <Column span="2" key="avatar">
        <AvatarWrapper>
          <AvatarContent alignment={alignment}>
            <Avatar image={image} />
            <AvatarCaption alignment={alignment}>
              <Author>{author}</Author>
              <CompanyName dangerouslySetInnerHTML={{ __html: companyName }} />
            </AvatarCaption>
          </AvatarContent>
        </AvatarWrapper>
      </Column>
    );

    const elements = [bubbleColumn, avatarColumn];
    if (alignment == 'right') {
      elements.reverse();
    }

    return <CommendationContainer>{elements}</CommendationContainer>;
  }
}

export default Commendation;

import React, { PureComponent } from 'react';
import { Container, Header, ExclamationCircle, Text } from './styles';
import { translate } from 'react-i18next';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';

class VoucherInvalidPage extends PureComponent {
  constructor(props) {
    super(props);
    const { voucher } = this.props;
    this.state = { voucher: voucher };
  }

  handleChange = (value) => {
    this.setState({ voucher: value });
  };

  retryWithVoucher = () => {
    window.location.href = `/questionnaire/${this.props.marketplaceId}/${this.state.voucher}`;
  };

  render() {
    const { t, primaryColor, primaryColorLight, secondaryColor, secondaryColorLight } = this.props;
    return (
      <Container>
        <ExclamationCircle primaryColor={primaryColor} secondaryColor={secondaryColor}>
          <i className="fal fa-exclamation-circle" />
        </ExclamationCircle>
        <Header>{t('questionnaire:voucher_invalid:title')}</Header>
        <Text>{t('questionnaire:voucher_invalid:text')}</Text>
        <Text>{t('questionnaire:voucher_invalid:retry_text')}</Text>
        <Input
          kind="default"
          mode="default"
          name="questionaire"
          placeholder="Voucher Code"
          value={this.state.voucher}
          onChange={this.handleChange}
        />
        <br />
        <Button
          disabled={!this.state.voucher}
          primaryColor={primaryColor}
          primaryColorLight={primaryColorLight}
          secondaryColor={secondaryColor}
          secondaryColorLight={secondaryColorLight}
          onClick={this.retryWithVoucher}
        >
          {t('questionnaire:voucher_invalid:retry_button_text')}
        </Button>
      </Container>
    );
  }
}

export default translate()(VoucherInvalidPage);

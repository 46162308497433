import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import Views from 'components/molecules/Views';

import GlobalStyles from './GlobalStyles';
import theme from './theme';
import Layout from 'components/templates/Layout';

class App extends PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Layout Body={<Views />} />
        </>
      </ThemeProvider>
    );
  }
}

export default App;

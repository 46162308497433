import React, { PureComponent } from 'react';
import logo from 'assets/images/logo.svg';
import { Container, HeaderWrapper, LogoWrapper, ButtonWrapper, Logo } from './styles';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';

import Button from 'components/atoms/Button';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ scrollPos: window.pageYOffset });
  };

  scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  openQuestionnaire = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container scrolled={this.state.scrollPos > 80} visible={this.state.scrollPos > 750}>
        <HeaderWrapper>
          <LogoWrapper onClick={this.scrollToTop}>
            <Logo logo={logo} />
          </LogoWrapper>
          {this.props.showButton && (
            <ButtonWrapper>
              <Button onClick={this.openQuestionnaire} variant="header">
                {t('landing:callToActionTitle')}
              </Button>
            </ButtonWrapper>
          )}
        </HeaderWrapper>
      </Container>
    );
  }
}

export default withRouter(translate()(Header));

import React, { PureComponent } from 'react';
import logo from 'assets/images/logo.svg';
import { Container, HeaderWrapper, LogoWrapper, Logo, TitleWrapper, Title, ButtonWrapper } from './styles';
import { withRouter } from 'react-router';
import { translate } from 'react-i18next';

import Button from 'components/atoms/Button';

class QuestionnaireHeader extends PureComponent {
  openMarketingPage = (e) => {
    this.props.history.push('/');
  };

  render() {
    const { t, isInIFrame, logoHidden, primaryColor, secondaryColor, secondaryColorLight } = this.props;

    return (
      <Container secondaryColor={secondaryColor} secondaryColorLight={secondaryColorLight}>
        <HeaderWrapper
          onClick={isInIFrame ? null : this.openMarketingPage}
          style={{ cursor: isInIFrame ? 'default' : 'pointer' }}
        >
          <LogoWrapper
            style={{
              visibility: logoHidden || logoHidden == undefined ? 'hidden' : 'visible',
            }}
          >
            <Logo logo={logo} />
          </LogoWrapper>
          <TitleWrapper>
            <Title primaryColor={primaryColor} secondaryColor={secondaryColor}>
              {t('questionnaire:title')}
            </Title>
          </TitleWrapper>
          <ButtonWrapper>
            {/* <Button
              variant={'cancel'}
              onClick={this.openMarketingPage}>Abbrechen</Button> */}
          </ButtonWrapper>
        </HeaderWrapper>
      </Container>
    );
  }
}

export default withRouter(translate()(QuestionnaireHeader));

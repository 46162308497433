import styled from 'styled-components';

import { PRIMARY } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const Label = styled.label`
  display: flex;
`;

export const Check = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  border-radius: 50%;
`;

export const Box = styled.label`
  align-items: center;
  align-self: center;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.checked ? 'transparent' : '#e9e9e9')};
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
`;

export const Input = styled.input`
  display: none;
`;

import React, { PureComponent } from 'react';

import { Container, StyledCheckbox, StyledCheckboxLabel, StyledCheckboxInput } from './styles';

class Checkboxes extends PureComponent {
  render() {
    const { name } = this.props;

    return (
      <Container>
        {this.props.fieldData.options.map((option, index) => (
          <StyledCheckbox key={index}>
            <StyledCheckboxInput
              type="checkbox"
              id={option.id}
              name={name}
              value={option.value}
              onChange={() => this.props.handleCheckboxChange(index, this.props.fieldData.id)}
              checked={option.value}
            />
            <StyledCheckboxLabel>{option.label}</StyledCheckboxLabel>
          </StyledCheckbox>
        ))}
      </Container>
    );
  }
}

export default Checkboxes;

export const saveState = (state) => window.localStorage.setItem('persisted-forms', JSON.stringify(state));

export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('persisted-forms');

    if (serializedState) {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    return error;
  }
};

export const purgeCache = () => {
  window.localStorage.removeItem('persisted-forms');
};

import React, { PureComponent } from 'react';
import Button from 'components/atoms/Button';
import { Row, Column } from '../../../constants/grid';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { CallToActionContainer, CallToActionContentContainer, CallToActionContent, Title, Subtitle } from './styles';

class CallToAction extends PureComponent {
  redirect = () => {
    this.props.openQuestionnaire();
  };

  render() {
    const { title, subtitle, buttonText, onClick } = this.props;

    return (
      <CallToActionContainer>
        <Row>
          <CallToActionContentContainer>
            <CallToActionContent>
              <Title>{title}</Title>
              <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
              <Button variant="secondary" onClick={this.redirect}>
                {buttonText}
              </Button>
            </CallToActionContent>
          </CallToActionContentContainer>
        </Row>
      </CallToActionContainer>
    );
  }
}

export default CallToAction;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms ease;
  z-index: 1;
  background-color: ${(props) => (props.secondaryColor ? props.secondaryColor : '#22546e')};

  @media only screen and (min-width: 2000px) {
    background: transparent;
  }
`;

export const HeaderWrapper = styled.div`
  width: 1240px;
  padding: 12px 20px 10px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (min-width: 2000px) {
    padding: 20px;
    height: 90px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
`;

export const Logo = styled.img.attrs({
  src: (props) => props.logo,
  srcSet: (props) => `${props.logo} 1x, ${props.logo.replace('.png', '@2x.png')} 2x`,
})`
  align-self: center;
  height: 30px;
  object-fit: cover;
  object-position: left;

  @media only screen and (min-width: 2000px) {
    width: 100%;
    height: auto;
  }
`;

export const TitleWrapper = styled.div`
  margin-left: 27px;
`;

export const Title = styled.h1`
  margin: 0;

  display: none;

  @media only screen and (min-width: 2000px) {
    display: block;
    font-size: 28px;
    font-weight: 300;
    line-height: 2.21;
    letter-spacing: 0.8px;
    color: #ffffff;
  }
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  text-align: end;
`;

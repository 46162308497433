import React, { PureComponent } from 'react';

import { Container } from './styles';

class Layout extends PureComponent {
  render() {
    const { Header, Body, Footer } = this.props;
    return (
      <Container>
        {Header && Header}
        {Body && Body}
        {Footer && Footer}
      </Container>
    );
  }
}

export default Layout;

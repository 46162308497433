import React, { PureComponent } from 'react';

import Input from 'components/atoms/Input';

class QuestionaireInput extends PureComponent {
  handleChange = (value) => {
    this.props.handleChange(this.props.fieldData, value);
  };

  render() {
    return (
      <Input
        kind="default"
        mode="default"
        name="questionaire"
        placeholder={this.props.fieldData.placeholder}
        value={this.props.fieldData.value}
        onChange={this.handleChange}
        numberformat={this.props.fieldData.numberFormat}
        lines={this.props.fieldData.lines}
      />
    );
  }
}

export default QuestionaireInput;

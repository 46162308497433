import React, { PureComponent } from 'react';

import Input from '../../atoms/Input';
import Radio from '../../atoms/Radio/Radio';

import { Container, Label } from './styles';

class FreeForm extends PureComponent {
  handleRadioChange = (option) => {
    this.props.handleOpenSelectionRadioChange(this.props.fieldData, option);
  };

  handleInputChange = (index, option, value) => {
    this.props.handleOpenSelectionInputChange(this.props.fieldData, index, { ...option, label: value }, value);
  };

  onFocus = (option) => {
    this.props.handleOpenSelectionRadioChange(this.props.fieldData, option);
  };

  render() {
    const { options, placeholder } = this.props.fieldData;
    const selectedOptionID = this.props.fieldData.value ? this.props.fieldData.value.id : null;

    return (
      <div>
        {options.map((option, index) => {
          const isActive = option.id === selectedOptionID;

          return (
            <Container
              isActive={isActive}
              key={option.id}
              primaryColor={this.props.primaryColor}
              secondaryColor={this.props.secondaryColor}
            >
              <Radio
                id={option.id}
                name="freeForm"
                value={isActive}
                onChange={() => this.handleRadioChange(option)}
                primaryColor={this.props.primaryColor}
                secondaryColor={this.props.secondaryColor}
              />
              {option.editable ? (
                <Label>
                  <Input
                    kind="FreeForm"
                    mode={isActive ? 'isActive' : 'default'}
                    onFocus={this.onFocus.bind(this, option)}
                    placeholder={placeholder}
                    required
                    value={option.label}
                    onChange={this.handleInputChange.bind(this, index, option)}
                  />
                </Label>
              ) : (
                <Label htmlFor={option.id} isActive={isActive}>
                  {option.label}
                </Label>
              )}
            </Container>
          );
        })}
      </div>
    );
  }
}

export default FreeForm;

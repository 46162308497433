import React, { PureComponent } from 'react';
import { Container, Icon, Header, Text, ConsentButtons } from './styles';
import { translate } from 'react-i18next';
import Button from 'components/atoms/Button';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

class ThankYouPage extends PureComponent {
  render() {
    const { t, primaryColor, primaryColorLight, secondaryColor, secondaryColorLight } = this.props;
    return (
      <Container>
        <Icon>
          <i className="fal fa-address-card" />
        </Icon>
        <Header>{t('questionnaire:results:title')}</Header>
        <Text
          dangerouslySetInnerHTML={{
            __html: t('questionnaire:results:sub_title'),
          }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: t('questionnaire:results:share_results_text'),
          }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: t('questionnaire:results:get_in_contact_text'),
          }}
        />
        <ConsentButtons>
          <Button
            primaryColor={primaryColor}
            primaryColorLight={primaryColorLight}
            secondaryColor={secondaryColor}
            secondaryColorLight={secondaryColorLight}
            onClick={() => this.props.handleConsentDecision(true)}
            value="yes"
          >
            {t('questionnaire:results:button_text_positive')}
          </Button>
          <Button
            primaryColor={primaryColor}
            primaryColorLight={primaryColorLight}
            secondaryColor={secondaryColor}
            secondaryColorLight={secondaryColorLight}
            onClick={() => this.props.handleConsentDecision(false)}
            value="no"
            variant="transparent"
          >
            {t('questionnaire:results:button_text_negative')}
          </Button>
        </ConsentButtons>
      </Container>
    );
  }
}

export default translate()(ThankYouPage);

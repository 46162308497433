import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
  width: 58%;
`;

export const SelectWrapper = styled.div`
  margin-left: 5px;
  width: 38%;
`;

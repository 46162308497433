import React, { PureComponent } from 'react';

import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { fetchSuggestions } from '../../../utils/api';
import { times } from 'lodash';
import { translate } from 'react-i18next';

const IndicatorSeparator = ({ innerProps }) => {
  return <span />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search select__autocomplete__dropdown-indicator" />
    </components.DropdownIndicator>
  );
};

const MenuList = (props) => {
  const { t } = props.selectProps;
  return (
    <components.MenuList {...props}>
      <div className="select__autocomplete__menu__header">{t('input:search_suggestions')}</div>
      {props.children}
    </components.MenuList>
  );
};

const NoOptionsMessage = (props) => {
  const { t } = props.selectProps;
  return (
    props.selectProps.inputValue && (
      <components.NoOptionsMessage {...props}>{t('input:no_results')}</components.NoOptionsMessage>
    )
  );
};

const LoadingMessage = (props) => {
  const { t } = props.selectProps;
  return (
    <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
      {t('input:loading')} ...
    </div>
  );
};

const Option = (props) => {
  const level = props.data.level;
  const input = props.selectProps.inputValue;
  let content;
  if (level) {
    let icons = times(level, (index) => (
      <i key={index} className="far fa-angle-right select__autocomplete__option__level__chevron" />
    ));
    content = <span className="select__autocomplete__option__level">{icons}</span>;
  }
  return (
    <components.Option {...props}>
      {content}
      {props.children}
    </components.Option>
  );
};

class AutocompleteSelect extends PureComponent {
  handleSearchedPhrase = (searchPhrase) => {
    const url = `${this.props.baseUrl}${this.props.fieldData.source}`.replace(':param', searchPhrase);
    return fetchSuggestions(url);
  };

  handleSelectClick = (value) => {
    this.props.handleAutocompleteClick(value, this.props.fieldData);
  };

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'red' : 'blue',
      }),
    };

    return (
      <AsyncSelect
        className="select"
        classNamePrefix="select__autocomplete"
        loadOptions={this.handleSearchedPhrase}
        onChange={this.handleSelectClick}
        value={this.props.fieldData.value}
        components={{
          IndicatorSeparator,
          DropdownIndicator,
          MenuList,
          Option,
          NoOptionsMessage,
          LoadingMessage,
        }}
        defaultOptions={this.props.fieldData.showDefaultOptions}
        isClearable
        placeholder={this.props.fieldData.placeholder}
        t={t}
        styles={customStyles}
      />
    );
  }
}

export default translate()(AutocompleteSelect);

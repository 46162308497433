import styled from 'styled-components';

import { PRIMARY, WHITE } from 'constants/colors';

export const NavigationContainer = styled.div`
  margin-bottom: 60px;
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    color: ${WHITE};
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    margin: 5px;

    &:hover {
      color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
      border-bottom: 1px solid ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
    }
  }
`;

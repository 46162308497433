import styled from 'styled-components';
import { TUNDORA, WHITE, GRAY_05, BONDI_BLUE } from 'constants/colors';
import { Box } from '../../atoms/Radio/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => {
    const color = props.primaryColor ? props.primaryColor : BONDI_BLUE;
    return props.isActive ? color : 'transparent';
  }};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 9px;

  &:hover {
    background-color: ${(props) => {
        const color = props.primaryColor ? props.primaryColor : BONDI_BLUE;
        return props.isActive ? color : GRAY_05;
      }}
      ${Box} {
      border-color: ${(props) => {
        const color = props.primaryColor ? props.primaryColor : BONDI_BLUE;
        return props.isActive ? 'transparent' : color;
      }};
    }
  }
`;

export const Label = styled.label`
  font-size: 18px;
  color: ${(props) => (props.isActive ? WHITE : TUNDORA)};
  margin-left: 5px;
  width: 100%;
  cursor: pointer;
`;

import styled from 'styled-components';

import { REGAL_BLUE, OXFORD_BLUE, WHITE } from 'constants/colors';

export const Container = styled.div`
  margin: 20px 0;
`;

export const Label = styled.label`
  display: block;
  margin: 5px 0;
`;

export const StyledTextArea = styled.textarea`
  background-color: ${WHITE};
  border: 1px solid ${REGAL_BLUE};
  width: 100%;
  height: 100%;
  outline: none;
  resize: none;
  height: 100px;
  padding: 10px;
  color: ${OXFORD_BLUE};
`;

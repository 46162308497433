import styled from 'styled-components';

import { WHITE } from 'constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 40px;
`;

export const HeroContainer = styled.div`
  align-items: flex-end;
  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position-y: bottom;
  `}
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 850px;
  width: 100%;
  overflow-x: hidden;
`;

export const Header = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const LanguageSelector = styled.div`
  float: right;
  color: white;
  margin-top: 8px;
`;

export const LanguageSelectorLink = styled.a`
  color: white;
  padding: 0 10px;
  margin: 10px 0;

  &:nth-child(n + 2) {
    border-left: 1px solid white;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 0;

  width: 100%;
  @media only screen and (min-width: 2000px) {
    width: 53%;
    padding: 43px 40px 0 0;
  }
`;

export const ImageWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 2000px) {
    display: block;
  }
  width: 766px;
  text-align: center;
  margin-right: -70px;
`;

export const Heading = styled.h1`
  color: ${WHITE};

  font-size: 40px;
  @media only screen and (min-width: 2000px) {
    font-size: 58px;
  }
  font-weight: 700;
  margin: 0 0 24px 0;
`;

export const Subheading = styled.h2`
  color: ${WHITE};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 0 16px 0;
  opacity: 0.8;
`;

export const ReportExampleImage = styled.img.attrs({
  src: (props) => props.image,
  srcSet: (props) => `${props.image} 1x, ${props.image.replace('.png', '@2x.png')} 2x`,
})`
  width: 100%;
  height: auto;
`;

export const ImageSubheading = styled.h2`
  color: ${WHITE};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1.3px;
  line-height: 1.6;
  margin: 28px 0 0 0;
  opacity: 0.8;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;  
`

export const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;    
  width: 75%;  
  height: 550px;
  @media only screen and (max-width: 1200px) {
    height: 420px;
  }
`

export const Iframe = styled.iframe.attrs({
  src: (props) => props.src
})`
  width: 100%;
  height: 100%;
`
